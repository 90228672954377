import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './stylesPub.css'
const Publications = (props) => {
return (
<Container id={"publications"} fluid data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" className={"no-pad-container sal-animate about-container timeline-block"}>
    <Row>
        <Col lg={12}>
            <section className={"c-section"}>
                <h2 className={"c-section__title  pr-0 pl-0 pl-80px"}><span>My Publications</span></h2>
            </section>
        </Col>
    
    {/* <!-- card 1 --> */}
    <div class="col-xs-12 col-sm-12 col-md-4 card-wrapper mb-3">
      <div className="row col-lg-12">
        <div className="col-lg-4">
          <div class="card-wrapper-image">
            <img class="card-img" src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/Dell_logo_2016.svg/1200px-Dell_logo_2016.svg.png" />        
          </div>
        </div>
        <div className="col-lg-8">
          <div class="card-wrapper-content">
          <h4>Automatic Best Fit Disk Selection and Assignment on Raid Disk Failure</h4>
          <br/>
          <span>Aug 3, 2020  Publication : DELL Tech Readers</span>          
          </div>
        </div> 
      </div>
    </div>

    {/* <!-- card 2 --> */}
    <div class="col-xs-12 col-sm-12 col-md-4 card-wrapper mb-3">
      <div className="row col-lg-12">
        <div className="col-lg-4">
          <div class="card-wrapper-image">
            <img class="card-img" src="https://ijcseonline.org/images/ijcselogo_01.png" />        
          </div>
        </div>
        <div className="col-lg-8">
          <div class="card-wrapper-content">
            {/* Original line Implementation of Reliable Fault Tolerant Data Storage System over Cloud using Raid – 60  */}
            <h4>Fault Tolerant Data Storage System over Cloud using Raid – 60</h4>
            <br/>
            <span>Mar 4, 2016  Publication : International Journal of Computer Sciences and Engineering</span>              
          </div>
        </div> 
      </div>
    </div>

    {/* <!-- card 3 --> */}
    <div class="col-xs-12 col-sm-12 col-md-4 card-wrapper mb-3">
      <div className="row col-lg-12">
        <div className="col-lg-4">
          <div class="card-wrapper-image">
            <img class="card-img" src="https://1000logos.net/wp-content/uploads/2019/03/IEEE-Logo.jpg" />        
          </div>
        </div>
        <div className="col-lg-8">
          <div class="card-wrapper-content">
            <h4>An approach to IoT based car parking and reservation system on Cloud</h4>
            <br/>
            <span>Jan 2016 Publication : IEEE (Publication Number:7370053)</span>           
          </div>
        </div> 
      </div>
    </div>
    
  </Row>
</Container>
)
}

export default Publications;