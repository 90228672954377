import React from "react";
class Tab extends React.Component {

    render() {
        return (
            <div className={`tabs__content`}>
                {/* <h3>{this.props.tab.name}</h3> */}
                <div className="timeline-container">
                        <div className="left text-wrapper">
                            <div className="text">
                            <h3 className={"title-position"}>{this.props.tab.title1}</h3>
                            <h2 className={"title-date"}>{this.props.tab.date1}</h2>
                            <h2  className={"title-company"}>{this.props.tab.event1}</h2>
                            </div>
                        </div>
                        {this.props.tab.event2 !== undefined ? 
                        <div className="right text-wrapper">
                            <div className="text">
                            <h3 className={"title-position"}>{this.props.tab.title2}</h3>
                            <h2 className={"title-date"}>{this.props.tab.date2}</h2>
                            <h2 className={"title-company"}>{this.props.tab.event2}</h2>
                            </div>
                        </div>
                        : ''}
                        {this.props.tab.event3 !== undefined ? 
                        <div className="left text-wrapper">
                            <div className="text">
                            <h3 className={"title-position"}>{this.props.tab.title3}</h3>
                            <h2 className={"title-date"}>{this.props.tab.date3}</h2>
                            <h2  className={"title-company"}>{this.props.tab.event3}</h2>
                            </div>
                        </div>
                        : ''}
                        {this.props.tab.event4 !== undefined ? 
                        <div className="right text-wrapper">
                            <div className="text">
                            <h3 className={"title-position"}>{this.props.tab.title4}</h3>
                            <h2 className={"title-date"}>{this.props.tab.date4}</h2>
                            <h2  className={"title-company"}>{this.props.tab.event4}</h2>
                            </div>
                        </div>
                        : ''}
                        {this.props.tab.event5 !== undefined ? 
                        <div className="left text-wrapper">
                            <div className="text">
                            <h3 className={"title-position"}>{this.props.tab.title5}</h3>
                            <h2 className={"title-date"}>{this.props.tab.date5}</h2>
                            <h2  className={"title-company"}>{this.props.tab.event5}</h2>
                            </div>
                        </div>
                        : ''}
                        {this.props.tab.event6 !== undefined ? 
                        <div className="right text-wrapper">
                            <div className="text">
                            <h3 className={"title-position"}>{this.props.tab.title6}</h3>
                            <h2 className={"title-date"}>{this.props.tab.date6}</h2>
                            <h2  className={"title-company"}>{this.props.tab.event6}</h2>
                            </div>
                        </div>
                        : ''}
                    </div>
            </div>
        );
    }
}
export default Tab;