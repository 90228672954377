import React, { Fragment } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Boxes from "./Boxes";
import './Hero.css';
import imgHello from '../Hero/hello.png';

const Hero = props => {
  return (
    <Container fluid className={"no-pad-container hero-container"}>
      <Row>
        <Col lg={12} className={"no-pad-col"}>        
          <Fragment>
            <section className={"hero"}>
              <Container lg={12}>
                <Row>
                  <Col lg={12} sm={12}>
                  <h1 id={"about"} className={"hero-description"}>
                    Hi! I'm Parminder Singh</h1> <br/>
                    <div className={"pb-1"}>
                      <p className={"sub-title"}>
                      24+ Granted Patents (USPTO) | 120+ Patent Application | Innovator | Mentor | Software Principal Engineer | Design Thinking | Vert.x 
                      </p>
                    </div>
                    <div className={"pb-0"}>  
                      <p className={"sub-title"}>Presently working as Software Principal Engineer (5years in Dell) & Patent Committee Member in Dell EMC</p>
                    </div> 
                    {/* <div className={"pb-10"}>
                      <p className={"sub-title"}>I am 27 years old, born and brought up in Ludhiana, Punjab. Got affiliated
                        with Dell EMC RnD (Bangalore) as a College graduate in 2016 and presently working as Software Principal Engineer. 
                        In the college time frame, I had been working as a freelance software engineer and had a network of approximately 
                        35+ clients across the globe.
                      </p>
                    </div>   */}
                  </Col>
                  </Row>
              </Container>
              <Boxes />
              <Col lg={12} sm={12}  className="hero-about-me-wrapper">
                    <div className={"pb-10 hero-about-me-container"} style={{paddingRight: "20px"}}>
                        <h2  style={{display: 'block', fontSize: '90px',top: '60px'}}>
                            <br/>     
                            Something About Me
                            <br/>       
                            <p style={{color: '#fff',fontSize: "21px"}}>My love for programming started early! During my college days, 
                            I took up to work as a freelance programmer and sooner I built a network of 35+ clients across the globe! 
                            I’m surprised as to what passion can do to your interests! 
                            </p>       
                            <p style={{color: '#fff',fontSize: "21px",marginTop: "10px"}}>
                              I didn’t stop there! What’s more better than converting your passion to professionalism! 
                              This love for programming landed me at Dell Technologies, Inc! Dell took me to greater heights from a college graduate 
                              to shaping me into a Senior Software Engineer and encouraged me to bring the innovator out of me! 
                            </p>
                            <p style={{color: '#fff',fontSize: "21px",marginTop: "10px"}}>
                            So far I have worked on JAVA, vertx, Dockers, multi-threading, JavaScript, php, machine learning, 
                            Device management domain, Cloud computing, predictive analytics problem solving.
                            At 25, this isn’t enough, you always have scope for more learning isn’t it? So I’m still
                            enthusiastically grabbing knowledge about edge technology, IoT and its communication.
                            </p>
                        </h2>
                      
                    </div>
                    <img src={imgHello} className={'hero-about-me-img'} />
                  </Col>     
                
            </section>
        </Fragment>
      </Col>
    </Row>
  </Container>
  );
};

export default Hero;
