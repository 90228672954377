import React from "react";
import Navigation from './navigation_tabs';
import Tab from './tab';
import './tabs.css';
class Tabs extends React.Component {
    state = {
        active: this.props.tabs[0]
    };

    onActiveTabs = (id) => {
        this.setState({
            active: this.props.tabs.find((item) => (
                item.id === id
            ))
        });
    };


    render() {
        return (
            <div className={`tabs`}>
                <Navigation tabs={this.props.tabs} 
									          onButtonClick={this.onActiveTabs} 
									          isActive={this.state.active.id} />
                <Tab tab={this.state.active} />
            </div>
        );
    }
}

export default Tabs;