import React from "react";
import Col from 'react-bootstrap/Col';
class Navigation extends React.Component {
    render() {
        const {isActive, onButtonClick} = this.props;

        return (
            <Col lg={12}>
                <ul className={`tabs__nav`}>
                    {this.props.tabs.map((item) => (
                            <li key={item.id} className={`tabs__item`}>
                            <button className={`text-yellow-300 tabs__button ${
                                                        (isActive === item.id) ? 'active' : ''
                                                                    }`}
                                    onClick={() => onButtonClick(item.id)} >
                                {item.name}
                            </button>
                            </li>
                    ))}
                </ul>
            </Col>
        );
    }
}
export default Navigation;