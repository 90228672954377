import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './about.css';
import FooterImageLeft from "../footerImageLeft";
const About = (props) => {
    return (
        <>
        
        <Container fluid id={"projects"}  className={"no-pad-container about-container project-container pb-10"}  >    
          
        <Row>
            <Col lg={12} className={"no-pad-col"}>
                <section className={"c-section"}>
                    <h2 className="c-section__title pr-0 pl-0 pl-80px"><span>My Projects</span></h2>
                    <ul className={"c-services"}>                        
                        <li className={"c-services__item"}>
                        <h3>Ring Me Not</h3>
                        <p>
                            Ring me not is a work-life balancing solution for corporate people,businessmen, entrepreneurs 
                            and any other who don't want to be engaged during non-office hours. This Application saves you 
                            from carrying two phones, or dual sim, rather a Ring-Me-NOT user gets a VPN(Virtual Private Number) 
                            which he can give to his clients as his work Number.

                            User can set any custom message that he wants to automatically delivered at time he is not available, 
                            by using mobile application.
                        </p>
                        </li>
                        <li className={"c-services__item"}>
                        <h3>Festival(s) Management System</h3>
                        <p>
                            The project was developed on SalesForce Cloud Platform
                            the aim of this project is to learn and get familiar with the "Force" platform( cloud platform). In this project , application has been developed which focus on managing festivals considering :
                            - different business logins
                            - How to make the system automatically manage the festivals.

                            At last this application has been scaled to be performed for multiple festivals and everything can be managed from 
                            one single portal.
                        </p>
                        </li>
                        <li className={"c-services__item"}>
                        <h3>Machine Learning</h3>
                        <p>Implementation of Machine Learning on Unstructured Data - The aim of this project is to highlight the important techniques and methodologies that are employed in text documents classification, while at the same time making awareness of some of the interesting challenges that remain to be solved, focused mainly on text representation and machine learning techniques. This paper provides a review of the theory and methods of document classification and text mining, focusing on the existing literature.</p>
                        </li>
                        <li className={"c-services__item"}>
                        <h3>Social Network Search Engine</h3>
                        <p>
                            This Android application helps user to find any person on all most every social network like Facebook, Instagram and 
                            many more. In its next version user can control all his/her accounts by one application only like updating 
                            status and etc. This make ease for users to manage all social accounts by one app only.
                            <br/>
                            <a href="https://github.com/parmindersinghsethi94/Search_on_Social" target="_blank">GitHub</a>
                        </p>
                        </li>
                        <li className={"c-services__item"}>
                        <h3>Juntos</h3>
                        <p>How many times has it been that you wanted to get some time off and take a trip but were not able to 
                            because you did not know where to go, or 3 of your friends wanted to just relax and the other two were 
                            interested in some sort of an adventure.
                            It gives us the top locations based on the polls and sentiment analysis using social media (twitter) 
                            and among the top 2 or three locations that effectively cover all your friend's interests you can 
                            choose the best one for you.
                            <br/>
                            APIs and Technologies used: HPE Haven OnDemand, Expedia APIs, NodeJS, Twitter API, Android, 
                            Amazon AWS, Advanced PHP, Advanced Java, HPE Sentimental Analysis API
                        </p>
                        </li>
                    </ul>
                </section>
            </Col>
        </Row>
        </Container>
        </>
    )
}
export default About;
