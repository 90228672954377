import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Img from "../image";
import './timeline.css';
import Tabs from '../Tabs/tabs';

const Timeline = (props) => {
    const tabsdata = [
        {
            id: 1,
            name: "Industrial Experiences",
            title1: 'Software Principal Engineer',
            event1: "Dell EMC R&D Bengaluru, India",
            date1: "April 2022 - Present",
            title2: 'Senior Software Engineer',
            event2: "Dell EMC R&D Bengaluru, India",
            date2: "March 2020 - March 2022",
            title3: 'Software Engineer II',
            event3:"Dell EMC R&D Bengaluru, India",            
            date3: "Sep 2018 - Feb 2020",
            title4: 'Software Engineer I',
            event:"Dell EMC R&D Bengaluru, India",
            date4: "Aug 2016 - Sep 2018",
            title5: 'Internship',
            event5:"Xebia IT Architects Bengaluru, India",
            date5: "May 2015 - July 2015"
        },
        {
            id: 2,
            name: "Volunteer Experience",
            title1: 'Mentor For Change',
            event1: "Atal Innovation Mission by Govt. of India",
            date1: "Feb 2020 - Present",
            title2: 'Patent Committee Member',
            event2:"Dell EMC Bengaluru, India",            
            date2: "May 2019 - Present",
            title3: 'Extended Patent Committee Member',
            event3:"Dell EMC Bengaluru, India",
            date3: "Feb 2018 - May 2019"
        },
        {
            id: 3,
            name: "Certifications",
            event1: "International Business Machines",
            title1: "Enterprise Design Thinking Practitioner",
            date1: "Aug 2020",            
            event2: "Pivotal Software, Inc.",
            title2:"Pivotal Cloud Foundry - Developer Certification",            
            date2: "Oct 2018",
            event3: "International Business Machines",
            title3:"Big Data - Fundamentals",            
            date3: "Oct 2015",
            event4: "Rackspace",
            title4:"CloudU Certificate in Cloud Computing",            
            date4: "Sep 2015",
            event5: "International Business Machines",
            title5:"IBM Bluemix Workshop Certificate",            
            date5: "Jun 2015",
            event6: "Xebia India",
            title6:"Hadoop Developer",            
            date6: "Apr 2015",            
        },
        {
            id: 4,
            name: "My Education",
            title1: "Purdue University (Online - Simplilearn)",
            event1:"Post Graduate in AI and Machine learning",
            date1:  "2020 – 2021",
            title2: "B.Tech - Computer Science",
            event2: "UPES Dehradun,India",
            title3: "High School",
            event3:"B.C.M Ludhiana Punjab,India",
            date2: "May 2012 - 2016",
            date3: "April 2002 - 2012"
            
        },        
    ];
    
    return (
        <Container id={"timeline"} fluid data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" className={"no-pad-container sal-animate about-container timeline-block"}>
            <Row>
                <Col lg={7} className={"pr-0 pl-0"}>
                            <section className={"c-section"}>
                                <h2 className={"c-section__title pr-0 pl-0 pl-80px"}><span>My Timeline</span></h2>
                            </section>
                </Col>
                <Col lg={4} className={"pr-0 pl-0"}>
                    <Img />
                </Col>
                <Col lg={12} className={'timline-text-block'}>                    
                    <Tabs tabs={tabsdata} />                                 
                </Col>
            </Row>
        </Container>
    )
}
export default Timeline;
