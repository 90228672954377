import React, { Fragment } from "react";
import PropTypes from "prop-types";

const propTypes = {
  name: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  r: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number
};

const Box = props => {
  const { name, x, y, r, width, height } = props;
  const styleObj = {
          fontWeight: 'lighter',
          fontSize: '20px',
          transform: `translate3d(${x}px, ${y}px, 0) rotate(${r}deg)`,
          height: `${height}px`,
          width: `${width}px`,
          maxHeight: '74px',
          transformOrigin: 'center center',
          position: 'absolute',
          border: '1px solid white',
          background: 'rgba(255, 255, 255, 0.15)',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
}
  return (
    
    <Fragment>
      
      {y ? <div className="box" style={styleObj} >{name}</div> : null}
      
    </Fragment>
  );
};

Box.propTypes = propTypes;

export default Box;
